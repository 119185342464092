import { Component } from '@angular/core';
import { IApplication, IRole, Page } from 'froitzheim-shared';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/dto/user.dto';
import * as XLSX from 'xlsx';


type ResolverData = {
  employee: User;
  applications: IApplication[];
}
@Component({
  selector: 'app-employee-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class EmployeeApplicationsComponent implements Page {
  get title() {
    return `Angträge von ${this.employee?.fullName}`;
  }
  employee?: User;
  applications: IApplication[] = [];

  constructor(
    private route: ActivatedRoute,
  ) {    
    this.resolveData(route.snapshot.data as ResolverData);
    this.route.data.subscribe(x => this.resolveData(x as ResolverData));
   }

  async resolveData(data: ResolverData): Promise<void> {
    this.employee = Object.assign(new User, data.employee);
    this.applications = data.applications;
    console.log(this.employee, this.applications);
  }

  sum(application: IApplication) {
    let total = 0;
    application.payments?.map(x => {
      total += x.amount;
    });
    return total;
  }

  sumDisplay(application: IApplication) {
    const total = this.sum(application);
    if (total > 0)
      return total + '€';
    else
      return '';
  }

  sumAll() {
    let total = 0;
    this.applications.map(x => total += this.sum(x));
    return total;
  }

  export() {
    const book = XLSX.utils.book_new();
    const data = this.applications.map(x => ({
      ID: x.application_id,
      Kunde: [x.person?.firstName, x.person?.name].filter(x => x).join(' '), 
      Datum: x.created_at?.toISOString(),
      Summe: this.sum(x),
    }));
    const sheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(book, sheet, this.title);
    XLSX.writeFile(book, `export-${this.employee?.firstName}_${this.employee?.name}.xlsx`);
  }
}
