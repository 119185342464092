<p-tabView>
  <p-tabPanel *ngIf="p_payment" header="Zahlungen">
    <p-table
      [value]="application.payments"
      class=""
      [rows]="10"
      [scrollable]="true"
      scrollHeight="flex">
      <ng-template pTemplate="header" let>
        <tr>
          <th pSortableColumn="date">
            Datum
            <p-sortIcon field="date"></p-sortIcon>
            <p-columnFilter
              type="date"
              field="date"
              display="menu"
              class="ml-auto"></p-columnFilter>
          </th>
          <th>Betrag</th>
          <th class="justify-content-end">
            <div class="flex">
              <p-button
                *ngIf="p_payment"
                styleClass="p-button-rounded"
                label="Neu"
                (onClick)="addPayment()">
              </p-button>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-payment>
        <tr
          [ngClass]="{
            refunded: payment.state === PaymentState.Refunded
          }">
          <td class="full-width">
            <p-calendar
              [disabled]="!!payment.payment_id || payment.storno"
              [(ngModel)]="payment.date"
              dateFormat="dd.mm.yy"
              appendTo="body">
            </p-calendar>
          </td>
          <td pEditableColumn class="full-width">
            <div [class]="payment.amount >= 0 ? 'green-text' : 'red-text'">
              <p-inputNumber
                [disabled]="!!payment.payment_id || payment.storno"
                type="text"
                [(ngModel)]="payment.amount"
                mode="currency"
                currency="EUR"
                locale="de-DE"
                [minFractionDigits]="2">
              </p-inputNumber>
            </div>
          </td>
          <td class="justify-content-end">
            <span *ngIf="payment.storno"
              >STORNO vom {{ payment.storno.date | date }}</span
            >
            <span *ngIf="hasStorno(payment)">STORNIERT</span>
            <div
              class="flex"
              *ngIf="
                payment.payment_id &&
                !payment.storno &&
                !hasStorno(payment)
              ">
              <button
                pButton
                (click)="doStorno(payment)"
                class="p-button-danger p-button-outlined">
                Stornieren
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="grid">
      <div class="col-12">Summe: {{ sum }}€</div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Antrag">
    <app-general-data
      #generalCmp
      [application]="application"
      [insurance]="insurance"
      [referers]="referers"
      [selectedReferer]="selectedReferer"
      [collapsed]="false"
      class="col-12">
    </app-general-data>
    <app-previous-insurance
      #previousInsuranceCmp
      [insurance]="previousInsurance"
      [collapsed]="false"
      class="col-12">
    </app-previous-insurance>
    <app-employer-data
      #employmentCmp
      [employment]="employment"
      [collapsed]="false"
      class="col-12">
    </app-employer-data>

    <p-panel
      header="Dateien"
      toggler="header"
      [collapsed]="false"
      [toggleable]="true"
      class="clickable col-12 surface-card shadow-2 border-round p-0">
      <strong *ngIf="files?.length">Hochgeladene Dateien</strong>
      <p-dataView [value]="files">
        <ng-template let-file pTemplate="listItem">
          <div class="flex">
            <p-tag [value]="getFileTag(file)"></p-tag>
            <div class="mx-2">
              <a class="cursor-pointer" (click)="downloadFile(file)">
                {{ file.originalFileName }}
              </a>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                class="custom-delete p-button-rounded p-button-danger"
                (click)="confirmDelete($event, file)"></button>
            </div>
          </div>
        </ng-template>
      </p-dataView>
      <div class="flex flex-wrap">
        <div class="upload-container">
          <strong>Personalausweis</strong>
          <p-fileUpload
            #cmp
            name="file"
            [multiple]="true"
            [customUpload]="true"
            [showUploadButton]="true"
            (uploadHandler)="upload($event, FileType.Personal_ID)">
          </p-fileUpload>
        </div>
        <div class="upload-container">
          <strong>Krankenkarten</strong>
          <p-fileUpload
            #cmp
            name="file"
            [multiple]="true"
            [customUpload]="true"
            [showUploadButton]="true"
            (uploadHandler)="upload($event, FileType.HealthInsurance)">
          </p-fileUpload>
        </div>
        <div class="upload-container">
          <strong>Sonstige Dateien</strong>
          <p-fileUpload
            #cmp
            name="file"
            [multiple]="true"
            [customUpload]="true"
            [showUploadButton]="true"
            (uploadHandler)="upload($event)">
          </p-fileUpload>
        </div>
      </div>
    </p-panel>
  </p-tabPanel>
  <p-tabPanel header="Person">
    <p-panel
      *ngIf="application?.person"
      header="Persönliche Informationen"
      class="clickable col-12 surface-card shadow-2 border-round p-0"
      toggler="header"
      [toggleable]="true">
      <app-personal-info-form
        class="col-12"
        [person]="$any(application.person)"></app-personal-info-form>
    </p-panel>

    <app-bank-input
      #bankCmp
      [person]="$any(application.person)"
      [collapsed]="false"
      class="col-12"></app-bank-input>

    <ng-container *ngIf="application?.person?.family">
      <p-panel
        *ngIf="application?.person as person"
        header="Familie"
        class="clickable col-12 surface-card shadow-2 border-round p-0"
        toggler="header"
        [toggleable]="true">
        <div *ngIf="person.family as family" class="family">
          <div class="owner flex flex-column">
            <strong>Eigentümer</strong>
            <span
              >{{ family.owner.firstName }} {{ family.owner.name }}
            </span>
            <button
              *ngIf="family.owner.person_id !== person.person_id"
              pButton
              label="Als Eigentümer setzen"
              (click)="setOwner(person)"></button>
          </div>

          <div class="members flex flex-column">
            <strong>Mitglieder</strong>
            <span *ngFor="let member of family.members"
              >{{ member.firstName }} {{ member.name }}
            </span>
          </div>
        </div>
        <div *ngIf="!person.family" class="no-family">
          <button
            pButton
            label="Familie anlegen"
            (click)="createFamily(person)"></button>
        </div>
      </p-panel>
    </ng-container>
  </p-tabPanel>
  <p-tabPanel header="Formulare">
    <div class="forms">
      <div class="form-list">
        <h3>Auswahl</h3>
        <div class="entries">
          <div
            *ngFor="let form of forms"
            class="entry"
            (click)="loadForm(form)">
            {{ form.formName }}
          </div>
        </div>
        <p-button *ngIf="canPrint" (click)="pdfEditor.print.print()">
          Drucken
        </p-button>
      </div>
      <div class="form-preview">
        <h3>Vorschau</h3>
        <ejs-pdfviewer
          #pdfEditor
          id="pdfEditor"
          locale="de"
          [serviceUrl]="service"
          [documentPath]="document"
          [enableToolbar]="false"
          [enableNavigationToolbar]="false"
          (documentLoad)="documentLoaded($event)"
          style="display: block">
        </ejs-pdfviewer>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
