import { Component } from '@angular/core';
import { IApplication, IFile, IRole, Page, Permissions } from 'froitzheim-shared';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/dto/user.dto';
import * as XLSX from 'xlsx';
import { PermService } from 'src/services/perm.service';
import { Application } from 'src/dto/application.dto';
import { ApplicationService } from 'src/services/application.service';
import { lastValueFrom } from 'rxjs';


type ResolverData = {
  employee: User;
  applications: IApplication[];
}
@Component({
  selector: 'app-employee-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class EmployeeApplicationsComponent implements Page {
  get title() {
    return `Angträge von ${this.employee?.fullName}`;
  }
  employee?: User;
  
  p_update = this.hasPerm((perm: Permissions) => perm.Administration.Customers.update);
  appDialog = false;
  applications: IApplication[] = [];
  application?: Application;
  files: IFile[] = [];
  uiBlock = false;
  
  
  constructor(
    private route: ActivatedRoute,
    private appService: ApplicationService,
    private perms: PermService,
    
  ) {    
    this.resolveData(route.snapshot.data as ResolverData);
    this.route.data.subscribe(x => this.resolveData(x as ResolverData));
  }
  
  async resolveData(data: ResolverData): Promise<void> {
    this.employee = Object.assign(new User, data.employee);
    this.applications = data.applications;
    console.log(this.employee, this.applications);
  }
  
  sum(application: IApplication) {
    let total = 0;
    application.payments?.map(x => {
      total += x.amount;
    });
    return total;
  }
  
  sumDisplay(application: IApplication) {
    const total = this.sum(application);
    if (total > 0)
      return total + '€';
    else
    return '';
  }
  
  sumAll() {
    let total = 0;
    this.applications.map(x => total += this.sum(x));
    return total;
  }
  
  export() {
    const book = XLSX.utils.book_new();
    const data = this.applications.map(x => ({
      ID: x.application_id,
      Kunde: [x.person?.firstName, x.person?.name].filter(x => x).join(' '), 
      Datum: x.created_at?.toISOString(),
      Summe: this.sum(x),
    }));
    const sheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(book, sheet, this.title);
    XLSX.writeFile(book, `export-${this.employee?.firstName}_${this.employee?.name}.xlsx`);
  }
  
  hasPerm(condition: (perm: Permissions) => boolean) {
    return this.perms.hasPerm(condition);
  }
  
  
  hideDialog() {
    this.files = [];
    this.appDialog = false;
    this.uiBlock = false;
  }
  
  async saveDialog() {
    if (!this.application?.application_id)
      return;
    this.uiBlock = true;
    try {
      const application = await lastValueFrom(this.appService.update(this.application));
      const existing = this.applications.find(x => x.application_id === application.application_id);
      if(existing) Object.assign(existing.person ?? {}, application.person);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      this.uiBlock = false;
    }
  }
  
  async selectApplication(app: IApplication) {
    if (!app.application_id)
      return;
    const { application, insuranceData } = await this.appService.getByIdAsync(app.application_id);
    const active = new Application();
    Object.assign(active, application);
    this.application = active;
    this.application.previousInsurance!.data = insuranceData;
    this.appDialog = true;
  }
}
