import { IEmployer, IEmployment } from 'froitzheim-shared';
import { BaseDTO } from './base.dto';

export class Employer extends BaseDTO implements IEmployer {
  employer_id?: number;
  name?: string;
  city?: string;
  postcode?: string;
  street?: string;
  streetNumber?: string;
  phone?: string;
  email?: string;
  employments: IEmployment[] = [];
  public = false;

  get address(): string {
    return `${this.street} ${this.streetNumber}, ${this.postcode} ${this.city}`;
  }
}
