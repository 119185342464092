import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEmployment, IEmployer, Salary } from 'froitzheim-shared';
import moment from 'moment';
import { Dropdown } from 'primeng/dropdown';
import { lastValueFrom } from 'rxjs';
import { Employer } from 'src/dto/employer.dto';
import { Employment } from 'src/dto/employment.dto';
import { EnumMap, EnumToArray } from 'src/helpers/enum.helpers';
import { EmployerService } from 'src/services/employer.service';
import { ToastService } from 'src/services/toast.service';
@Component({
  selector: 'app-employer-data',
  templateUrl: './employer-data.component.html',
  styleUrls: ['./employer-data.component.scss'],
})
export class EmployerDataComponent implements OnInit {
  salaries = EnumToArray(Salary).filter(x => x.key !== 0);
  Salary = Salary;

  minDate = moment().startOf('month').add(-70, 'year').toDate();
  maxDate = moment().toDate();
  datePlaceholder = moment().add(-5, 'years').format('DD.MM.yy');
  employerOptions: number[] = [];
  employers: Record<string, IEmployer> = {};
  
  selectedEmployer?: IEmployer = undefined;
  employer?: Employer;
  submittedNewEmployer = false;
  showNewEmployerDialog = false;

  uiBlock = false;

  @Input() employment?: IEmployment;
  @Input() collapsed = false;
  @ViewChild('employerSelection') employerSelection?: Dropdown;

  form = new FormGroup({
    employer: new FormGroup({
      employer_id: new FormControl(undefined), //, Validators.required),
      name: new FormControl(undefined), //, Validators.required),
      street: new FormControl(undefined), //, Validators.minLength(2)),
      streetNumber: new FormControl(undefined), //, Validators.minLength(1)),
      city: new FormControl(undefined), //, Validators.minLength(2)),
      postcode: new FormControl(undefined), //, Validators.minLength(2)),
    }),
    employment: new FormGroup({
      start_date: new FormControl(undefined, Validators.required),
      salary: new FormControl(undefined, Validators.required)
    })
  });

  constructor(
    private readonly employerService: EmployerService, 
    private toast: ToastService,
    ) { }
  async ngOnInit() {
    const options = await lastValueFrom(this.employerService.getAvailable());
    this.employerOptions = options.map(x => x.employer_id as number);
    this.employers = options.reduce((curr, val) => {
      if(!val?.employer_id) return curr;
      curr[val.employer_id] = val;
      return curr;
    }, this.employers);
    if(this.employment){
      this.form.patchValue({employment: this.employment, employer: this.employment.employer});
    }
    
    this.form.valueChanges.subscribe(x => {
      const empployerID = x.employer.employer_id;
      const employer = this.employers[empployerID];
      if(!this.employment)
        this.employment = {};
      
      if(!this.employment.employer)
        this.employment.employer = new Employer();
      Object.keys(x.employment).forEach(key => (this.employment as any)[key] = x.employment[key]);
      Object.assign(this.employment.employer, employer);
      this.form.patchValue({employer: employer}, {emitEvent: false});


    });
  }

  newEmployer() {
    this.employerSelection?.hide();
    this.employer = new Employer();
    this.submittedNewEmployer = false;
    this.showNewEmployerDialog = true;
  }

  hideNewEmployeeDialog() {
    this.showNewEmployerDialog = false;
    this.submittedNewEmployer = false;
  }

  async saveEmployer() {
    this.submittedNewEmployer = true;
    if (!this.employer?.name || !this.employer?.street || !this.employer?.city || !this.employer?.postcode)
    return;
    this.uiBlock = true;
    setTimeout(()=> {
      this.uiBlock = false;
      this.showNewEmployerDialog = false;
    }, 1000);   
    const result = await lastValueFrom(this.employerService.insert(this.employer));
    if (this.employerOptions.find(x => x === result.employer_id)) {
      this.toast.warn('Arbeitgeber existiert bereits', 'Ein Arbeitgeber mit dem selben Namen und ähnlicher Addresse existiert bereits.');
      return;
    }
    if(result.employer_id){
      this.employerOptions.push(result.employer_id);
      this.employers[result.employer_id] = result;
    }
    if(this.employerSelection){
      this.employerSelection.selectedOption = result;
    }
    this.form.patchValue({employer: result});
  }
}
