<p-panel
  header="Bisherige Krankenversicherung"
  class="clickable col-12 surface-card shadow-2 border-round p-0 mb-4"
  toggler="header"
  [collapsed]="collapsed"
  [toggleable]="true"
  [formGroup]="form">
  <div class="formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="pastInsuranceRelation">Ich war zuletzt </label>
      <p-dropdown
        inputId="pastInsuranceRelation"
        [options]="pastInsuranceRelations"
        [optionValue]="'key'"
        formControlName="pastInsuranceRelation"
        styleClass="w-full"
        appendTo="body">
        <ng-template let-item pTemplate="selectedItem">
          <p>
            {{
              "pastInsuranceRelation." + item.value | lowercase | translate
            }}
          </p>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <p>
            {{
              "pastInsuranceRelation." + item.value | lowercase | translate
            }}
          </p>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="field col-12" *ngIf="isInsured">
      <label for="previousHealthInsurance">Krankenkasse</label>
      <p-dropdown
        inputId="previousHealthInsurance"
        [options]="healthInsurances"
        formControlName="healthInsurance"
        (onChange)="selectHealthInsurance()"
        [showClear]="true"
        [filter]="true"
        filterBy="name"
        optionValue="id"
        placeholder="Auswählen"
        [required]="true"
        styleClass="w-full"
        appendTo="body">
        <ng-template let-insurance pTemplate="selectedItem">
          <p>{{ insurance.name }}</p>
        </ng-template>
        <ng-template let-insurance pTemplate="item">
          <p>{{ insurance.name }}</p>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="field col-12" *ngIf="isInsured">
      <label for="insuranceNumber">Versicherungsnummer</label>
      <input
        id="insuranceNumber"
        type="text"
        class="w-full"
        pInputText
        [required]="true"
        minlength="2"
        formControlName="insurance_number" />
    </div>
    <div class="field col-12 md:col-3" *ngIf="isInsured">
      <label for="sample1">Von</label>
      <p-calendar
        inputId="sample1"
        styleClass="w-full"
        [minDate]="minDate"
        [maxDate]="insurance.end_date ?? maxDate"
        dateFormat="dd.mm.yy"
        placeholder="TT.MM.JJJJ"
        formControlName="start_date">
      </p-calendar>
    </div>
    <div class="field col-12 md:col-3" *ngIf="isInsured">
      <label for="sample2">Bis</label>
      <p-calendar
        inputId="sample2"
        styleClass="w-full"
        [minDate]="insurance.start_date ?? minDate"
        [maxDate]="maxDate"
        dateFormat="dd.mm.yy"
        placeholder="TT.MM.JJJJ"
        formControlName="end_date">
      </p-calendar>
    </div>
    <div
      class="grid col-12"
      *ngIf="
        insurance.pastInsuranceRelation ===
        PastInsuranceRelation.NotInsured
      ">
      <div class="field col-12 md:col-6" formGroupName="data">
        <label for="notInsuredSince">Seit</label>
        <p-calendar
          inputId="notInsuredSince"
          placeholder="TT.MM.JJJJ"
          dateFormat="dd.mm.yy"
          formControlName="notInsuredSince"
          styleClass="w-full"></p-calendar>
      </div>
      <div class="field col-12 md:col-6" formGroupName="data">
        <label for="notInsured">Grund</label>
        <input
          id="notInsured"
          type="text"
          formControlName="notInsuredReason"
          class="w-full"
          placeholder="z.B. privat versichert, Ausland"
          pInputText />
      </div>
    </div>
    <div
      class="grid col-12 align-items-end"
      *ngIf="
        insurance.pastInsuranceRelation ===
        PastInsuranceRelation.FamilyInsured
      ">
      <div class="field col-12 md:col-6" formGroupName="data">
        <label for="familyInsuredName">familienversichert über</label>
        <input
          id="familyInsuredName"
          type="text"
          formControlName="familyInsuredName"
          class="w-full"
          placeholder="Name, Vorname"
          pInputText />
      </div>

      <!-- <div class="field col-12 md:col-4">
        <label for="familyInsuredSince">Geburtsdatum</label>
        <p-calendar
          inputId="familyInsuredSince"
          styleClass="w-full"
          [(ngModel)]="insurance.data.familyInsuredSince">
        </p-calendar>
      </div> -->
      <div class="field col-12 md:col-6" formGroupName="data">
        <label for="familyInsuredNumber"
          >Versicherungsnummer des Familienversicherers</label
        >
        <input
          id="familyInsuredNumber"
          type="text"
          formControlName="familyInsuredNumber"
          class="w-full"
          pInputText />
      </div>
    </div>
  </div>
</p-panel>
