<p-panel
  header="Allgemeine Daten zum Antrag"
  class="clickable surface-card shadow-2 border-round col-12 p-0 mb-4"
  toggler="header"
  [collapsed]="collapsed"
  [toggleable]="true"
  [formGroup]="form">
  <div class="formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="insuranceType">Versicherungsstatus</label>
      <p-dropdown
        inputId="insuranceType"
        [options]="insurantType"
        [optionValue]="'key'"
        [required]="true"
        formControlName="type"
        appendTo="body"
        placeholder="keine Auswahl"
        [ngClass]="{
          'ng-invalid': insurance.type === InsurantType.Undefined
        }"
        styleClass="w-full">
        <ng-template let-item pTemplate="selectedItem">
          <p>
            {{ "insuranceType." + item.value | lowercase | translate }}
          </p>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <p>
            {{ "insuranceType." + item.value | lowercase | translate }}
          </p>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="field col-12 md:col-6">
      <label for="start">Ich möchte Mitglied werden zum</label>
      <p-calendar
        inputId="start"
        [minDate]="minDate"
        [maxDate]="maxDate"
        dateFormat="dd.mm.yy"
        placeholder="TT.MM.JJJJ"
        formControlName="start_date"
        styleClass=" w-full"></p-calendar>
    </div>

    <div class="field col-12">
      <label for="reason">Anlass des Krankenkassenwechsels</label>
      <p-dropdown
        inputId="reason"
        [options]="reasons"
        formControlName="reason"
        [optionValue]="'key'"
        styleClass="w-full"
        appendTo="body">
        <ng-template let-item pTemplate="selectedItem">
          <p>
            {{ "changeReason." + item.value | lowercase | translate }}
          </p>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <p>
            {{ "changeReason." + item.value | lowercase | translate }}
          </p>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="field col-12">
      <label for="healthInsurance">Ich möchte Mitglied werden bei</label>
      <p-dropdown
        appendTo="body"
        inputId="healthInsurance"
        [options]="filteredHealthInsurances"
        formControlName="healthInsurance"
        [showClear]="true"
        [filter]="true"
        filterBy="name"
        placeholder="Auswählen"
        [required]="true"
        (onChange)="onInsuranceChange($event)"
        styleClass="w-full">
        <ng-template let-insurance pTemplate="selectedItem">
          <p>{{ insurance.name }}</p>
        </ng-template>
        <ng-template let-insurance pTemplate="item">
          <p>{{ insurance.name }}</p>
        </ng-template>
      </p-dropdown>
    </div>

    <div
      class="field col-12"
      *ngIf="!hasReferalLink && referers && referers.length > 0">
      <label for="referer">Tippgeber</label>
      <p-dropdown
        inputId="referer"
        appendTo="body"
        [options]="referers"
        formControlName="selectedReferer"
        [showClear]="true"
        [filter]="true"
        filterBy="firstName"
        placeholder="Auswählen"
        styleClass="w-full">
        <ng-template let-referer pTemplate="selectedItem">
          <p>{{ referer.firstName }} {{ referer.name }}</p>
        </ng-template>
        <ng-template let-referer pTemplate="item">
          <p>{{ referer.firstName }} {{ referer.name }}</p>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</p-panel>
