import { Component, Input, OnInit } from '@angular/core';
import { ITerm, TermType } from 'froitzheim-shared';
import { Term } from 'src/dto/term.dto';
import { TermService } from 'src/services/term.service';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  @Input() type?: TermType;
  @Input() collapsed = false;

  terms: TermContainer[] = [];
  term?: Term;

  constructor(private service: TermService) { }
  ngOnInit(): void {
    if (this.type)
      this.loadTerms(this.type);
    this.loadTerms(TermType.All);
  }

  async loadTerms(type: TermType) {
    this.service.getAllByType(type).subscribe(x => x.map(term => this.terms.push({ agreed: false, term: term })));
  }

  openTerm(term: Term) {
    this.term = term;
  }
}

interface TermContainer {
  agreed: boolean;
  term: ITerm;
}

