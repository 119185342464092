import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
export class BaseService {
    constructor() {
    }
    private url?: string;
    public http!: HttpClient;
    public readonly path!: string;


    protected getUrl() {
        if (!this.url)
            this.url = ConfigService.settings.app.url + this.path;
        return <string>this.url;
    }

    protected getHeaders() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('id_token')}`
        });
        return { headers: headers };
    }

    protected getAuthHeaders() {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${localStorage.getItem('id_token')}`
        });
        return { headers: headers };
    }
}

export class Service<T> extends BaseService {
    public getAll(): Observable<T[]> {
        return this.http.get<T[]>(this.getUrl());
    }

    public getById(id: number): Observable<T> {
        return this.http.get<T>(this.getUrl() + '/' + id, this.getHeaders());
    }

    public insert(obj: T): Observable<T> {
        return this.http.post<T>(this.getUrl(), obj, this.getHeaders());
    }

    public update(obj: T): Observable<T> {
        return this.http.put<T>(this.getUrl(), obj, this.getHeaders());
    }

    public delete(id: number): Observable<any> {
        console.log(this.getUrl() + '/' + id);
        return this.http.delete<any>(this.getUrl() + '/' + id, this.getHeaders());
    }
}



