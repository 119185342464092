import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplication } from 'froitzheim-shared';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class StatisticsService extends BaseService {

  override readonly path = '/statistics';

  constructor(override http: HttpClient) {
    super();
  }

  public testMail(target: string): Observable<void> {
    const url = this.getUrl() + '/testMail';
    return this.http.post<void>(url, { target: target }, this.getHeaders());
  }

  public getMyApplications(from?: Date, till?: Date): Observable<IApplication[]> {
    let url = this.getUrl() + '/applications/my';
    if (from)
      url += '/' + from.toISOString();
    if (till)
      url += '/' + till.toISOString();
    return this.http.get<IApplication[]>(url, this.getHeaders());
  }

  public getApplications(refererId: number, from?: Date, till?: Date): Observable<IApplication[]> {
    let url = this.getUrl() + '/applications/' + refererId;
    if (from)
      url += '/' + from.toISOString();
    if (till)
      url += '/' + till.toISOString();
    return this.http.get<IApplication[]>(url, this.getHeaders());
  }
  public getAllApplications(): Observable<IApplication[]> {
    const url = this.getUrl() + '/applications/all' ;
    return this.http.get<IApplication[]>(url, this.getHeaders());
  }
  public getAllApplicationsRange(from: Date, till: Date): Observable<IApplication[]> {
    const url = `${this.getUrl()}/applications/all/${from?.toISOString()}/${till?.toISOString()}`;
    return this.http.get<IApplication[]>(url, this.getHeaders());
  }
}
