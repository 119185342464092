<div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card p-4 shadow-2 border-round flex flex-column gap-3">
        <div class="text-3xl font-medium text-900">{{ title }}</div>
        <div class="flex gap-3">
          <button
            pButton
            label="Zurück"
            [routerLink]="['/', 'overview', 'employees', employee?.user_id]"></button>
          <button
            pButton
            label="Tabelle exportieren"
            (click)="export()"></button>
        </div>

        <div
          style="min-height: 150px"
          class="border-2 border surface-border">
          <p-table [value]="applications">
            <ng-template pTemplate="header">
              <tr>
                <th>Name</th>
                <th>Geburtstag</th>
                <th>Erstellt</th>
                <th>Summe</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-application>
              <tr>
                <td>
                  {{ application.person.firstName }}
                  {{ application.person.name }}
                </td>
                <td>
                  {{ application.person.birthday | date: "longDate" }}
                </td>
                <td>
                  {{ application.created_at | date: "dd.MM - HH:mm" }}
                </td>
                <td>{{ sumDisplay(application) }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary" let-columns>
              <div>
                Summe:
                {{ sumAll() }}€
              </div>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
