import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { BankInputComponent } from './bank-input.component';
@NgModule({
  declarations: [
    BankInputComponent,
  ],
  imports:[
  CommonModule,
    FormsModule,
    InputTextModule,
    TabViewModule,
    PanelModule,
    TranslateModule,
    DropdownModule,
    CalendarModule,
    ReactiveFormsModule
],
  exports: [
    BankInputComponent,
  ]
})
export class BankInputModule { }