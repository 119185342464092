<div class="grid height">
  <div class="col-12 topbar flex">
    <div id="topbar-left" class="align-self-start">
      <p-button
      *ngIf="canGoBack"
      label="Zurück"
      styleClass="p-button-link"
      (onClick)="back($event)"></p-button>
    </div>
    <div *ngIf= "isLoggedIn && user" class="user flex align-items-center
    ">
      Hallo, {{user.firstname}} {{user.name}}
    </div>
    <div id="topbar-center" class="align-self-stretch flex-auto">
    </div>
    <div id="topbar-right" class="align-self-end">
      
      <p-megaMenu [model]="items"></p-megaMenu>
    </div>
  </div>

  <div class="col-12 height">
    <router-outlet></router-outlet>
  </div>
</div>
<p-toast></p-toast>
<p-confirmPopup defaultFocus="reject"></p-confirmPopup>
