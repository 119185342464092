import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Payload, Permissions } from 'froitzheim-shared';

export function forPerm(hasPerm: (perm: Permissions) => boolean) {
    return { data: { hasPerm }, canActivate: [PermGuard] };
}

@Injectable()
export class PermGuard implements CanActivate {
    constructor(private perm: PermService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkPermissions(route);
    }

    checkPermissions(route: ActivatedRouteSnapshot) {
        return this.perm.hasPerm(route.data['hasPerm']);
    }
}

@Injectable()
export class PermService {
    constructor(@Inject(JwtHelperService) private jwt: JwtHelperService) { }

    hasPerm(condition: (perm: Permissions) => boolean, jwt?: JwtHelperService) {
        const token = localStorage.getItem('id_token');
        if (!token)
            return false;
        const payload = <Payload>(this.jwt ?? jwt).decodeToken(token);
        try {
            return condition(payload.permissions) === true;
        } catch (error) {
            return false;
        }
    }
}