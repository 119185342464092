import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import {
  FamilyStatus,
  Gender,
  IEmployment,
  IInsurance,
  IPerson,
  ITerm,
  Permissions,
  IFamily,
} from 'froitzheim-shared';
import { BaseDTO } from './base.dto';

export class Person extends BaseDTO implements IPerson {
  constructor(@Inject(LOCALE_ID) private locale: string = 'de-DE') {
    super();
  }
  person_id!: number;
  name!: string;
  firstName!: string;
  birthday!: Date;
  gender!: Gender;
  familyStatus!: FamilyStatus;
  postcode!: string;
  city!: string;
  street!: string;
  streetNumber?: string;
  phone!: string;
  email!: string;
  iban!: string;
  bic!: string;
  permissions: Permissions = <Permissions>{};
  get fullName(): string {
    return (this.firstName + ' ' + this.name).trim();
  }
  get index(): string{
    return this.fullName + ' '+ this.bithdayDate;
  }
  get birthdayLongDate(): string {
    const pipe = new DatePipe(this.locale);
    return pipe.transform(this.birthday, 'longDate') ?? '';
  }

  get bithdayDate(): string {
    const pipe = new DatePipe(this.locale);
    return pipe.transform(this.birthday, 'd.M.yyyy') ?? '';
  }
}

export class Family extends BaseDTO implements IFamily {
  id!: number;
  members: IPerson[] = [];
  owner: IPerson;
  constructor(owner: IPerson) {
    super();
    this.owner = owner;
  }
}