import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Company, CompanyEntries, EmploymentType, IRole, ITransaction, IUser, Page, Permissions } from 'froitzheim-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { User, Credential } from 'src/dto/user.dto';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { ToastService } from 'src/services/toast.service';
import { EnumMap, EnumToArray } from 'src/helpers/enum.helpers';
import { PermService } from 'src/services/perm.service';
import md5 from 'md5';
import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';
import { ApplicationService } from 'src/services/application.service';
import { DateTime } from 'luxon';
import { TransactionService } from 'src/services/transaction.service';

type ResolverData = {
  employee: User;
  roles: IRole[];
  limited?: true;
  transactions: ITransaction[];
}
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements Page {
  items: MenuItem[] = [{ label: 'Admin', url: '/admin' }, { label: 'Mitarbeiter', url: '/admin/employees' }, {}];
  title = 'Kunde';
  employee?: User;
  roles: IRole[] = [];
  selectedRoles: number[] = [];
  password = '';
  id!: number;
  employmentTypes: EnumMap[] = EnumToArray(EmploymentType);
  p_delete = this.hasPerm((perm: Permissions) => perm.Administration.Employees.delete);
  p_update = this.hasPerm((perm: Permissions) => perm.Administration.Employees.update);
  p_read = this.hasPerm((perm: Permissions) => perm.Administration.Employees.read);

  CompanyEntries = CompanyEntries;
  provision!: number;
  uiBlock = false;

  transactions = {
    from: DateTime.now().startOf('month').toJSDate(),
    to: DateTime.now().endOf('month').toJSDate(),
    data: [] as ITransaction[],
  };

  async loadTransactions() {
    this.transactions.data = await this.transactionService.summarizeUserTransactionsRange(
      this.employee?.user_id as number, 
      DateTime.fromJSDate(this.transactions.from), 
      DateTime.fromJSDate(this.transactions.to)
    );
    console.log(this.transactions);
  }

  get parent() {
    return this.employee?.parent;
  }
  tree: TreeNode[] = [];

  referer_identifierOptions = this.CompanyEntries.filter(x => x.toString() !== 'something').map(x => x);
  
  toTree(referer: IUser, parent?: IUser, notUrl?: boolean): TreeNode {
    referer.provision = Number.parseFloat(referer.provision.toString());
    return {
      label: referer.firstName + ' ' + referer.name,
      data: {
        referer,
        link: ['/', 'admin', 'employees', referer.user_id]
      },
      parent,
      type: notUrl ? '' : 'url',
      children: referer.children.filter(x => x !== undefined).map(x => this.toTree(x, referer)),
    };
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private message: MessageService,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router,
    private service: UserService,
    private toast: ToastService,
    private confirmation: ConfirmationService,
    private applicationService: ApplicationService,
    private transactionService: TransactionService,
    private perms: PermService,
  ) {    
    this.route.data.subscribe(x => this.resolveData(x as ResolverData));
   }

  async resolveData(data: ResolverData): Promise<void> {
    console.log(data);
    this.roles = data.roles;
    this.employee = data.employee;
    if(!this.employee) return;
    this.tree = <TreeNode[]>[this.toTree(this.employee, undefined, true)];
    this.title = this.employee.firstName + ' ' + this.employee.name;
    this.provision = await lastValueFrom(this.service.getProvision(Number(this.employee.user_id)));
    this.employee.broker_identifier = this.employee.broker_identifier;
    this.employee.credentials = new Credential();
    this.selectedRoles = this.employee.roles.map(x => x.role_id);
    this.transactions.data = data.transactions;
  }

  async fetchEmployee(id: number): Promise<void> {
    this.employee= await lastValueFrom(this.service.getWithDescendants(id)) as any;
    if(!this.employee)
      return;
    
  }

  delete(event: Event) {
    this.confirmation.confirm({
      target: event.target ?? undefined,
      message: 'Soll dieser Mitarbeiter gelöscht werden?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.employee?.user_id)
          return;
        const result = lastValueFrom(this.service.delete(this.employee.user_id));
        console.log(result);
        this.toast.warn(`Mitarbeiter '${this.employee?.fullName}' wurde gelöscht!`);
        this.router.navigate(['..'], { relativeTo: this.route });
      },
    });
    return;
  }

  async invite() {
    if (!this.employee?.user_id) return;
    await lastValueFrom(this.service.putOTLEditMail(this.employee.user_id));
    this.message.add({severity: 'success', summary: 'Link versendet'});
  }
  
  async submit() {
    if (this.employee) {
      this.uiBlock = true;
      this.employee.roles = this.roles.filter(x => this.selectedRoles.includes(x.role_id));
      this.employee.credentials = undefined;
      const result = await lastValueFrom(this.service.update(this.employee));
      Object.assign(this.employee, result);
      if (this.password && this.employee?.user_id) {
        await lastValueFrom(this.service.setPassword(this.employee.user_id, this.password));
      }
      this.uiBlock = false;
      this.message.add({severity: 'success', summary: 'Gespeichert'});
    }
  }

  hasPerm(condition: (perm: Permissions) => boolean) {
    return this.perms.hasPerm(condition);
  }

  countDescendants(referer: IUser = this.employee as IUser) {
    let i = referer.children.length;
    referer.children.map(x => this.countDescendants(x)).reduce((v, c) => i += c, i);
    return i;
  }

  handleChange(e: any) {
    const index = e.index;
    if (!index || !this.employee?.applications)
      return;
    const application = this.employee.applications[index];
    if (!application || !application.application_id)
      return;
    this.loadApplication(application.application_id, index);
  }

  loadApplication(id: number, index: number) {
    this.applicationService.getById(id).subscribe(x => {
      if (!this.employee || !this.employee.applications)
        return;
      this.employee.applications[index] = x;
    });
  }

  async generateReferal() {
    if (!this.employee)
      return;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.employee.referal = md5(this.employee.firstName + this.employee.name! + this.employee.user_id);
    this.submit();
  }

  copyReferal() {
    if (!this.employee?.referal)
      return;
    const path = this.document.location.origin + '/?referal=' + this.employee.referal;
    this.clipboard.copy(path);
    this.message.add({ severity: 'success', 'summary': 'Link in die Zwischenablage kopiert' });
  }
  copyRefererReferal() {

    if (!this.employee?.referal)
      return;
    const path = this.document.location.origin + '/referer?referal=' + this.employee.referal;
    this.clipboard.copy(path);
    this.message.add({ severity: 'success', 'summary': 'Link in die Zwischenablage kopiert' });
  }

  public getParentBroker(c: Company){
    let identifier = undefined;
    let parent = this.employee?.parent;
    while(!identifier && parent){
      identifier = JSON.parse((parent as any).broker_identifier_json ?? '{}')?.[c];
      parent = parent.parent;
    }
    return identifier ?? '';
  }
}
