import { Component, Input, OnInit } from '@angular/core';
import { PastInsuranceRelation, InsurantType, IInsurance } from 'froitzheim-shared';
import healthInsurances from '../../assets/insurances.json';
import moment from 'moment';
import { EnumMap, EnumToArray } from 'src/helpers/enum.helpers';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-previous-insurance',
  templateUrl: './previous-insurance.component.html',
  styleUrls: ['./previous-insurance.component.scss'],
})
export class PreviousInsuranceComponent implements OnInit {
  PastInsuranceRelation = PastInsuranceRelation;
  insuranceTypes: EnumMap[] = EnumToArray(InsurantType);

  InsurantType = InsurantType;
  healthInsurance?: HealthInsurance;

  healthInsurances: HealthInsurance[] = healthInsurances;
  pastInsuranceRelations: EnumMap[] = EnumToArray(PastInsuranceRelation);
  pastInsuranceRelation: PastInsuranceRelation = PastInsuranceRelation.Insured;
  minDate = moment().startOf('month').add(-70, 'year').toDate();
  maxDate = moment().endOf('month').toDate();
  datePlaceholderMin = moment().add(-5, 'years').format('DD.MM.yy');
  datePlaceholderMax = moment().format('DD.MM.yy');

  @Input() insurance!: IInsurance;
  @Input() collapsed = false;

  form = new FormGroup({
    healthInsurance: new FormControl(undefined), //, Validators.required),
    pastInsuranceRelation: new FormControl(PastInsuranceRelation.Insured), //, Validators.required),
    insurance_number: new FormControl(undefined), //, Validators.minLength(2)),
    start_date: new FormControl(undefined), //, Validators.required),
    end_date: new FormControl(undefined), //, Validators.required),
    data: new FormGroup({
      notInsuredSince: new FormControl(undefined),
      notInsuredReason: new FormControl(undefined),
      familyInsuredName: new FormControl(undefined),
      familyInsuredNumber: new FormControl(undefined)
    })
  });

  isInsured = true;
  constructor() { }
  ngOnInit(): void {
    this.insurance.data ??= <any>{};

    if (this.insurance.healthInsurance)
      this.healthInsurance = this.healthInsurances.find(x => x.id == this.insurance.healthInsurance);

    this.form.patchValue({
      ...this.insurance,
      healthInsurance: this.healthInsurance?.id
    });
    this.isInsured = this.insurance.pastInsuranceRelation !== PastInsuranceRelation.NotInsured;
    this.form.valueChanges.subscribe(x => {
      Object.assign(this.insurance, x);
      this.insurance.healthInsurance = x.healthInsurance?.id;
      if(x.pastInsuranceRelation === PastInsuranceRelation.NotInsured){
        this.form.get(['insurance_number'])?.disable({emitEvent: false});
        this.form.get(['healthInsurance'])?.disable({emitEvent: false});
        this.form.get(['start_date'])?.disable({emitEvent: false});
        this.form.get(['end_date'])?.disable({emitEvent: false});
        this.isInsured = false;
      } else {
        this.form.get(['insurance_number'])?.enable({emitEvent: false});
        this.form.get(['healthInsurance'])?.enable({emitEvent: false});
        this.form.get(['start_date'])?.enable({emitEvent: false});
        this.form.get(['end_date'])?.enable({emitEvent: false});
        this.isInsured = true;
      }
    });

  }

  selectHealthInsurance() {
    if (!this.insurance)
      return;
    this.insurance.healthInsurance = this.form.value.healthInsurance;
  }

  selectInsuranceRelation(event: any) {
    this.insurance.data ??= <any>{};
  }
}

type HealthInsurance = {
  id: number,
  name: string
}