import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRole } from 'froitzheim-shared';
import { Service } from './base.service';

@Injectable()
export class RoleService extends Service<IRole> {
  
  constructor(override http: HttpClient) {
    super();
  }

  override readonly path = '/role';
  
  public getUsers(roleId: string) {
    const url = this.getUrl() + `${roleId}/users`;
    return this.http.get<IRole[]>(url, this.getHeaders());
  }

  public clear(roleId: string) {
    const url = this.getUrl() + `${roleId}/clear`;
    return this.http.post<void>(url, {},this.getHeaders());
  }
}
