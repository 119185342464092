import { ChangeReason, IApplication, IInsurance, IPerson, IUser, IPayment, IEmployment } from 'froitzheim-shared';
import { BaseDTO } from './base.dto';
import { Insurance } from './insurance.dto';
import { Person } from './person.dto';

export class Application extends BaseDTO implements IApplication {
  application_id?: number;
  person?: IPerson;
  start_date?: Date;
  creation_date?: Date;
  reason?: ChangeReason;
  serialized?: string;
  referer?: IUser;
  previousInsurance?: IInsurance;
  insurance?: IInsurance;
  payments: IPayment[] = [];
  employment?: IEmployment;
}
