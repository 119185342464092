<p-panel
  header="Bankverbindung"
  class="clickable surface-card shadow-2 border-round col-12 p-0 mb-4"
  toggler="header"
  [collapsed]="collapsed"
  [toggleable]="true"
  [formGroup]="form">
  <div class="formgrid grid">
    <!-- <div class="field col-12">
      <label for="cardName">Kontoinhaber</label>
      <input
        id="cardName"
        type="text"
        class="inputfield w-full"
        [required]="true"
        minlength="2"
        pInputText />
    </div> -->
    <div class="field col-12 md:col-8">
      <label for="iban">IBAN</label>
      <input
        id="iban"
        type="text"
        formControlName="iban"
        class="inputfield w-full"
        minlength="12"
        pInputText />
      <small
        *ngIf="
          form.get('iban')?.errors && form.get('iban')?.errors?.['ibanInvalid']
        "
      >
        <span *ngIf="form.get('iban')?.errors?.['error']['countryUnsupported']">
          Land nicht unterstützt
        </span>
        <span *ngIf="form.get('iban')?.errors?.['error']['codeLengthInvalid']">
          Länge ungültig
        </span>
        <span *ngIf="form.get('iban')?.errors?.['error']['patternInvalid']">
          IBAN nicht gültig
        </span>
      </small>
    </div>
    <!-- <div class="field col-12 md:col-4">
      <label for="bic">BIC</label>
      <input
        id="bic"
        type="text"
        formControlName="bic"
        class="inputfield w-full"
        [required]="true"
        minlength="2"
        pInputText />
    </div> -->
  </div>
</p-panel>
