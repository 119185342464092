import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { EmployerDataComponent } from './employer-data.component';
@NgModule({
  declarations: [
    EmployerDataComponent,
  ],
  imports:[
  CommonModule,
    FormsModule,
    InputTextModule,
    TabViewModule,
    PanelModule,
    TranslateModule,
    DropdownModule,
    CalendarModule,
    ReactiveFormsModule,
    BlockUIModule,
    DialogModule
],
  exports: [
    EmployerDataComponent,
  ]
})
export class EmployerDataModule { }