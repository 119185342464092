import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[appForbiddenValue]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true }]
})
export class ForbiddenValidatorDirective implements Validator {
    @Input('appForbiddenValue') forbiddenValue = '';

    validate(control: AbstractControl): ValidationErrors | null {
        console.log('validator', this.forbiddenValue);
        return this.forbiddenValue ? forbiddenValueValidator(this.forbiddenValue)(control)
            : null;
    }
}

export function forbiddenValueValidator(value: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const forbidden = value == control.value;
        return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
}