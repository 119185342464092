import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from 'froitzheim-shared';
import { Observable } from 'rxjs';
import { Service } from './base.service';

@Injectable()
export class RefererService extends Service<IUser> {
  
  constructor(override http: HttpClient) {
    super();
  }

  override readonly path = '/user';
  
  getMe(): Observable<unknown> {
    return this.http.get<IUser>(this.getUrl() + '/me', this.getHeaders());
  }
  public getByReferal(referal: string): Observable<IUser> {
    return this.http.get<IUser>(this.getUrl() + '/referal/' + referal, this.getHeaders());
  }

  public addApplication(id: number, applicationId: number): Observable<IUser> {
    return this.http.post<IUser>(`${this.getUrl()}/${id}/application/${applicationId}`, {});
  }

  public deleteApplication(id: number, applicationId: number): Observable<IUser> {
    return this.http.delete<IUser>(`${this.getUrl()}/${id}/application/${applicationId}`);
  }

  public count() {
    return this.http.get<number>(`${this.getUrl()}/count`);
  }

  public getWithDescendants(id: number): Observable<IUser> {
    return this.http.get<IUser>(`${this.getUrl()}/${id}/children`, this.getHeaders());
  }

  public getAllWithDescendants(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.getUrl()}/children`, this.getHeaders());
  }

  public getMine(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.getUrl()}/mine`, this.getHeaders());
  }

  public getInsurances(id: number): Observable<number[]> {
    return this.http.get<number[]>(`${this.getUrl()}/${id}/insurances`, this.getHeaders());
  }
  public setInsurances(id: number, insurances: number[]): Observable<number[]> {
    return this.http.put<number[]>(`${this.getUrl()}/${id}/insurances`, insurances, this.getHeaders());
  }

  public getTotalProvision(id: number): Observable<number> {
    return this.http.get<number>(`${this.getUrl()}/${id}/total_provision`, this.getHeaders());
  }

  public getProvision(id: number): Observable<number> {
    return this.http.get<number>(`${this.getUrl()}/${id}/provision`, this.getHeaders());
  }
}
