<p-panel
  *ngIf="(terms?.length ?? 0) > 0"
  header="Bestimmungen"
  class="clickable surface-card shadow-2 border-round col-12 p-0 mb-4"
  toggler="header"
  [collapsed]="collapsed"
  [toggleable]="false">
  <div class="formgrid grid">
    <div class="field col-12 flex align-items-start" *ngFor="let container of terms">
      <ng-container
        *ngTemplateOutlet="
          container.term.optional ? optional : mandatory;
          context: { $implicit: container }
        "></ng-container>
    </div>
  </div>
</p-panel>

<ng-template #optional let-container>
  <p-checkbox
    [(ngModel)]="container.agreed"
    [binary]="true"
    [inputId]="container.term.term_id">
  </p-checkbox>
  <label [for]="container.term.term_id" class="m-0 ml-2">
    (optional) Ich akzeptiere die Bedingungen "<a
      (click)="openTerm(container.term)"
      href="#"
      onclick="event.preventDefault(); return false;"
      >{{ container.term.title }}</a
    >"
  </label>
</ng-template>
<ng-template #mandatory let-container>
  <p-checkbox
    [(ngModel)]="container.agreed"
    [binary]="true"
    [inputId]="container.term.term_id">
  </p-checkbox>
  <label [for]="container.term.term_id" class="m-0 ml-2">
    <strong>{{container.term.title}}</strong>
    <span class="mandatory">* </span>
    <p>{{container.term?.data}}</p> 
  </label>
</ng-template>

<p-dialog
  [modal]="true"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw', minHeight: '50vh' }"
  [dismissableMask]="true"
  [draggable]="false"
  [resizable]="false"
  [maximizable]="true"
  [header]="term?.title ?? ''"
  [(visible)]="term"
  (onHide)="term = undefined">
  <div class="pre">
    {{ term?.data }}
  </div>
</p-dialog>
