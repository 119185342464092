<p-panel
  header="Arbeitgeberdaten"
  class="clickable surface-card shadow-2 border-round col-12 p-0 mb-4"
  [collapsed]="collapsed"
  toggler="header"
  [toggleable]="true"
  [formGroup]="form">
  <div class="formgrid grid" *ngIf="employment && employment.employer">
    <div class="field col-12 md:col-6" formGroupName="employer">
      <label for="workName">Arbeitgebername</label>
      <p-dropdown
        #employerSelection
        styleClass="w-full"
        [required]="true"
        [options]="employerOptions"
        formControlName="employer_id"
        placeholder="Arbeitgeber eingeben oder auswählen"
        [showClear]="true"
        appendTo="body">
        <ng-template pTemplate="selectedItem" let-item>
          {{ employers[item].name }}
        </ng-template>
        <ng-template pTemplate="item" let-item>
          {{ employers[item].name }}
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="m-2">
            <p-button
              label="Arbeitgeber anlegen"
              (onClick)="newEmployer()">
            </p-button>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="field col-12 md:col-6" formGroupName="employment">
      <label for="employedSince">Beschäftigt seit </label>
      <p-calendar
        inputId="employedSince"
        [minDate]="minDate"
        [maxDate]="maxDate"
        placeholder="TT.MM.JJJJ"
        formControlName="start_date"
        dateFormat="dd.mm.yy"
        class="ng-dirty"
        styleClass="w-full">
      </p-calendar>
    </div>
    <div class="field col-9" formGroupName="employer">
      <label for="workAddress">Straße</label>
      <input
        id="workAddress"
        type="text"
        rows="4"
        class="w-full"
        [required]="true"
        minlength="2"
        formControlName="street"
        readonly="true"
        pInputText />
    </div>
    <div class="field col-3" formGroupName="employer">
      <label for="workAddressNumber">Hausnummer</label>
      <input
        id="workAddressNumber"
        type="text"
        rows="4"
        class="w-full"
        [required]="true"
        minlength="1"
        formControlName="streetNumber"
        readonly="true"
        pInputText />
    </div>
    <div class="field col-12 md:col-4" formGroupName="employer">
      <label for="workCity">Stadt</label>
      <input
        id="workCity"
        type="text"
        class="w-full"
        [required]="true"
        minlength="2"
        formControlName="city"
        readonly="true"
        pInputText />
    </div>
    <div class="field col-12 md:col-2" formGroupName="employer">
      <label for="workZip">PLZ</label>
      <input
        id="workZip"
        type="text"
        class="w-full"
        [required]="true"
        minlength="2"
        formControlName="postcode"
        readonly="true"
        pInputText />
    </div>
    <div div class="field col-12 md:col-6" formGroupName="employment">
      <label for="salary">monatliches Brutto-Arbeitsentgelt</label>
      <p-dropdown
        inputId="salary"
        [options]="salaries"
        formControlName="salary"
        [optionValue]="'key'"
        styleClass="w-full"
        [showClear]="true"
        placeholder="Auswählen"
        [required]="true"
        appendTo="body">
        <ng-template let-salary pTemplate="selectedItem">
          <p>{{ "salary." + salary.value | lowercase | translate }}</p>
        </ng-template>
        <ng-template let-salary pTemplate="item">
          <p>{{ "salary." + salary.value | lowercase | translate }}</p>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</p-panel>

<p-dialog
  [(visible)]="showNewEmployerDialog"
  *ngIf="employer"
  [style]="{ width: '450px' }"
  header="Arbeitgeber"
  [modal]="true"
  styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="grid">
      <div class="col-12">
        <label for="name">Name</label>
        <input
          type="text"
          pInputText
          id="name"
          [(ngModel)]="employer.name"
          required
          autofocus />
        <small
          class="p-error"
          *ngIf="submittedNewEmployer && !employer.name"
          >Name is required.</small
        >
      </div>
      <div class="col-9">
        <label class="block" for="workAddress">Straße</label>
        <input
          id="workAddress"
          type="text"
          rows="4"
          class="w-full"
          [required]="true"
          minlength="2"
          [(ngModel)]="employer.street"
          pInputText />
      </div>
      <div class="col-3">
        <label for="addressNumber">Hausnummer</label>
        <input
          name="addressNumber"
          type="text"
          rows="4"
          [(ngModel)]="employer.streetNumber"
          class="w-full"
          [required]="true"
          minlength="1"
          pInputText />
      </div>
      <div class="col-6">
        <label for="workCity">Stadt</label>
        <input
          id="workCity"
          type="text"
          class="w-full"
          [required]="true"
          minlength="2"
          [(ngModel)]="employer.city"
          pInputText />
      </div>
      <div class="col-6">
        <label for="workZip">PLZ</label>
        <input
          id="workZip"
          type="text"
          class="w-full"
          [required]="true"
          minlength="2"
          [(ngModel)]="employer.postcode"
          pInputText />
      </div>
      <!-- <div class="error col-12" *ngIf="errorMessage">
        {{ errorMessage }}
      </div> -->
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Abbrechen"
      icon="pi pi-times"
      class="p-button-text p-button-danger"
      (click)="hideNewEmployeeDialog()"></button>
    <p-blockUI [blocked]="uiBlock"></p-blockUI>
    <button
      pButton
      pRipple
      label="Speichern"
      icon="pi pi-check"
      class="p-button-text p-button-success"
      (click)="saveEmployer()"></button>
  </ng-template>
</p-dialog>
