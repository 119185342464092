export type EnumMap = Record<number, string>;

export function EnumToArray(
  e: any,
  filter?: (s: string) => string
): {key: any, value: any }[] {
  return Object.values(e)
    .filter((keys) => typeof keys === 'number')
    .map((x) => {
      const key = x;
      let value = e[x as number];
      if (filter !== undefined) value = filter(value);
      return {
        key,
        value,
      };
    });
}

export function EnumToRecord(
  e: any,
  filter?: (s: string) => string
): EnumMap {
  const start = {} as EnumMap;
  const data = <number[]>Object.values(e).filter((keys) => typeof keys === 'number');
  return data.reduce((curr, val) => {
      curr[val] = e[val];
    return curr;
  }, start);
}

export enum SubmitError {
  None = 0,
  Terms = 1,   
}