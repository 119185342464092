import { Injectable } from '@angular/core';
import axios from 'axios';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable()
export class AddressService {
    result: Subject<any> = new Subject();

    readonly path: string = '/address/';

    async fetchAddress(query: string) {
        const url = ConfigService.settings.app.url + this.path + query;
        const result = await axios.get(url);
        this.result.next(result.data);
    }
}
