import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TermsComponent } from './terms.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [
    TermsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    TabViewModule,
    PanelModule,
    TranslateModule,
    DropdownModule,
    CalendarModule,
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
  ],
  exports: [
    TermsComponent,
  ]
})
export class TermModule { }