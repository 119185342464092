import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, lastValueFrom } from 'rxjs';
import { EMailSetting, IInsurance, PayoutData, WebDAVSetting } from 'froitzheim-shared';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SettingsService extends BaseService {
  override readonly path = '/settings';

  constructor(private jwt: JwtHelperService, override http: HttpClient) {
    super();
  }
  public lastExport(): Observable<Date> {
    const url = this.getUrl() + '/lastExport';
    return this.http.get<Date>(url, this.getHeaders());
  }

  public setLastExport(date: Date): Observable<Date> {
    const url = this.getUrl() + '/lastExport/';
    return this.http.post<Date>(url, { date }, this.getHeaders());
  }

  public payout(): Observable<PayoutData> {
    const url = this.getUrl() + '/payout';
    return this.http.get<PayoutData>(url, this.getHeaders());
  }

  public setPayout(data: PayoutData): Observable<PayoutData> {
    const url = this.getUrl() + '/payout';
    return this.http.post<PayoutData>(url, data, this.getHeaders());
  }

  public getGeneralHealthInsurances(): Observable<IInsurance[]>{
    const url = this.getUrl() + '/insurances';
    return this.http.get<IInsurance[]>(url, this.getHeaders());
  }

  public setGeneralHealthInsurances(insurances: IInsurance[]): Observable<IInsurance[]>{
    const url = this.getUrl() + '/insurances';
    return this.http.post<IInsurance[]>(url, insurances, this.getHeaders());
  }

  public emailSetting(): Observable<EMailSetting> {
    const url = this.getUrl() + '/email';
    return this.http.get<EMailSetting>(url, this.getHeaders());
  }

  public setEmailSetting(data: EMailSetting): Observable<EMailSetting> {
    const url = this.getUrl() + '/email';
    return this.http.post<EMailSetting>(url, data, this.getHeaders());
  }
    

  public webdavSetting(id?: string) {
    let url = this.getUrl() + '/webdav';
    if (id) url += `/${id}`;
    return lastValueFrom(this.http.get<WebDAVSetting>(url, this.getHeaders()));
  }
  public setWebDAVSetting(data?: Partial<WebDAVSetting>, id?: string) {
    let url = this.getUrl() + '/webdav';
    if (id) url += `/${id}`;
    return lastValueFrom(this.http.post<WebDAVSetting>(url, data, this.getHeaders()));
  }
  
  public broker(id?: string) {
    let url = this.getUrl() + '/broker';
    if (id) url += `/${id}`;
    return lastValueFrom(this.http.get<any>(url, this.getHeaders()));
  }

  public setBroker(data: {value?: string}, id?: string) {
    let url = this.getUrl() + '/broker';
    if (id) url += `/${id}`;
    return lastValueFrom(this.http.post<any>(url, data, this.getHeaders()));
  }
}
