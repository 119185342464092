import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InsurantType, ChangeReason, IApplication, IInsurance, IUser } from 'froitzheim-shared';
import healthInsurances from '../../assets/insurances.json';
import { EnumMap, EnumToArray } from 'src/helpers/enum.helpers';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/services/settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

type ResolverData = {user: IUser}

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss'],
})
export class GeneralDataComponent implements OnInit {
  onInsuranceChange($event: {value: HealthInsurance}) {
    this.application.insurance!.healthInsurance = $event.value.id;
  }
  insurantType: EnumMap[] = EnumToArray(InsurantType).filter(x => x?.key);
  reasons: EnumMap[] = EnumToArray(ChangeReason);
  availableHealthInsurances: HealthInsurance[] = healthInsurances;
  minDate = moment().startOf('month').add(-1, 'month').toDate();
  maxDate = moment().endOf('month').add(12, 'month').toDate();
  datePlaceholder = moment().format('DD.MM.yy');

  InsurantType = InsurantType;
  filteredHealthInsurances: HealthInsurance[] = this.availableHealthInsurances;
  

  @Input() hasReferalLink = false;
  private _selectedReferer?: IUser | undefined;
  public get selectedReferer(): IUser | undefined {
    return this._selectedReferer;
  }
  @Input() public set selectedReferer(value: IUser | undefined) {
    if (this._selectedReferer !== value) this.selectedRefererChange.emit(value);
    this._selectedReferer = value;
    this.filteredHealthInsurances = this.getFilteredInsurances();
    if(this.form.value.selectedReferer === value) return;
    this.form.patchValue({
      selectedReferer: value,
    });
  }
  @Output() public selectedRefererChange = new EventEmitter<IUser>();
  @Input() application!: IApplication;
  @Input() insurance!: IInsurance;
  @Input() public referers!: IUser[];

  @Input() collapsed = false;

  form = new FormGroup({
    type: new FormControl(undefined, Validators.required),
    start_date: new FormControl(undefined, Validators.required),
    reason: new FormControl(undefined, Validators.required),
    healthInsurance: new FormControl(undefined, Validators.required),
    selectedReferer: new FormControl(undefined)
  });

  constructor(
    private readonly auth: AuthService, 
    private readonly user: UserService,
    private readonly settings: SettingsService,
    private readonly route: ActivatedRoute,
  ) {
  }

  private getFilteredInsurances(): HealthInsurance[] {
    const insurances = this.selectedReferer?.allowedInsurances ? healthInsurances.filter(x => this.selectedReferer?.allowedInsurances?.includes(x.id)) : this.availableHealthInsurances;
    const insurance = this.getInsurance(this.form.value.healthInsurance ?? this.insurance.healthInsurance);
    if (insurance) insurances.push(insurance);
    return  [...new Map(insurances.filter(x => x).map(item => [item.id, item])).values()];
  }

  async resolveData(data: ResolverData): Promise<void> {
    this.selectedReferer = data.user;
  }

  ngOnInit(): void {
    if (!this.application.reason)
      this.application.reason = ChangeReason.ExpirationCommitmentPeriod;
    this.settings.getGeneralHealthInsurances().subscribe(x => {
      this.availableHealthInsurances = <HealthInsurance[]>x;
      this.selectedReferer = this.selectedReferer;
    });
    this.resolveData(this.route.snapshot.data as ResolverData);
    this.form.patchValue({
      ...this.insurance,
      ...this.application,
      healthInsurance: this.getInsurance(this.insurance.healthInsurance)
    });
    this.form.valueChanges.subscribe(x => {
      this.application.start_date = x.start_date;
      this.application.reason = x.reason;
      this.insurance.type = x.type;
      this.insurance.healthInsurance = x.healthInsurance?.id;
      this.selectedReferer = x.selectedReferer;
    });
  }

  getInsurance(ID?: string | number) {
    if (!ID) return undefined;
    const insuranceID = typeof ID === 'number' ? ID : Number.parseInt(ID, 10);
    return healthInsurances.find(x => x.id === insuranceID);
  }
}

type HealthInsurance = {
  id: number,
  name: string
}