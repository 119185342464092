import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { ApplicationComponent } from './application.component';
import { TableModule } from 'primeng/table';
import { GeneralDataModule } from '../general-data/general-data.module';
import { PreviousInsuranceModule } from '../previous-insurance/previous-insurance.module';
import { EmployerDataModule } from '../employer-data/employer-data.module';
import { DataViewModule } from 'primeng/dataview';
import { FileUploadModule } from 'primeng/fileupload';
import { BankInputModule } from '../bank-input/bank-input.module';
import { PdfViewerModule } from '@syncfusion/ej2-angular-pdfviewer';
import { PersonalInfoFormModule } from '../personal-info-form/personal-info-form.module';
import { TagModule } from 'primeng/tag';
import { InputNumberModule } from 'primeng/inputnumber';
@NgModule({
  declarations: [
    ApplicationComponent,
  ],
  imports:[
  CommonModule,
    FormsModule,
    InputTextModule,
    TabViewModule,
    PanelModule,
    TranslateModule,
    DropdownModule,
    CalendarModule,
    ReactiveFormsModule,
    BlockUIModule,
    DialogModule,
    TableModule,
    GeneralDataModule,
    PreviousInsuranceModule,
    EmployerDataModule,
    DataViewModule,
    FileUploadModule,
    BankInputModule,
    PdfViewerModule,
    PersonalInfoFormModule,
    TagModule,
    InputNumberModule,
],
  exports: [
    ApplicationComponent,
  ]
})
export class ApplicationModule { }