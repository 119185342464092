/* eslint-disable @typescript-eslint/no-non-null-assertion */
import healthInsurances from '../../assets/insurances.json';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Application } from 'src/dto/application.dto';
import { User } from 'src/dto/user.dto';
import { Insurance } from 'src/dto/insurance.dto';
import { ChangeReason, FamilyStatus, FileType, Gender, HealthInsurance, IEmployment, IFamily, IFile, IForm, InsurantType, IPayment, IPerson, PastInsuranceRelation, PaymentState, PayoutData, Permissions } from 'froitzheim-shared';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { GeneralDataComponent } from 'src/components/general-data/general-data.component';
import { BankInputComponent } from 'src/components/bank-input/bank-input.component';
import { EmployerDataComponent } from 'src/components/employer-data/employer-data.component';
import { PreviousInsuranceComponent } from 'src/components/previous-insurance/previous-insurance.component';
import { ApplicationService } from 'src/services/application.service';
import { SettingsService } from 'src/services/settings.service';
import { DateTime } from 'luxon';
import { lastValueFrom, Observable, switchMap } from 'rxjs';
import { Employment } from 'src/dto/employment.dto';
import { PermService } from 'src/services/perm.service';
import { Family } from 'src/dto/person.dto';
import { ConfigService } from 'src/services/config.service';
import { LoadEventArgs } from '@syncfusion/ej2-pdfviewer';
import { PdfViewerComponent } from '@syncfusion/ej2-angular-pdfviewer';
import { HttpClient } from '@angular/common/http';
import { EnumMap, EnumToRecord } from 'src/helpers/enum.helpers';
import { Field } from 'src/services/form.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  public readonly service = 'https://aps.vigor-assurance.de/pdfviewer';
  @Input() application!: Application;
  @ViewChild('generalCmp') generalCmp!: GeneralDataComponent;
  @ViewChild('employmentCmp') employmentCmp!: EmployerDataComponent;
  @ViewChild('previousInsuranceCmp') previousInsuranceCmp!: PreviousInsuranceComponent;
  @ViewChild('bankCmp') bankCmp!: BankInputComponent;
  @ViewChild('cmp') fileUpload?: FileUpload;
  @ViewChild('pdfEditor') public pdfEditor!: PdfViewerComponent;
  
  public FileType = FileType;
  p_payment = this.hasPerm((perm: Permissions) => perm.Administration.Applications.payment);
  PaymentState = PaymentState;
  files: IFile[] = [];
  get insurance() {
    return this.application.insurance!;
  }
  get selectedReferer() {
    return this.application.referer;
  }
  get previousInsurance() {
    return this.application.previousInsurance!;
  }
  get employment() {
    return this.application.employment!;
  }

  referers: User[] = []; // TODO: fetch referers
  payouts: PayoutData = {};
  forms: IForm[] = [];
  canPrint = false;
  document?: string;
  
  constructor(
    private appService: ApplicationService,
    private readonly http: HttpClient,
    private settingsService: SettingsService,
    private confirmation: ConfirmationService,
    private messageService: MessageService,
    private perms: PermService,
    
  ) {}
  
  ngOnInit(): void {
    this.fetchFiles();
    console.log(this.application);
  }
  
  async fetchFiles() {
    const ID = this.application.application_id;
    this.files = await lastValueFrom(this.appService.getFiles(ID as number));
    console.log(ID, this.application, this.files);
  }
  
  addPayment() {
    if (!this.application.payments) this.application.payments = [];
    const date = DateTime.now();
    this.application.payments.push(new Payment(this.payouts?.[date.year] ?? 0, PaymentState.PaidIn));
  }
  
  hasStorno(payment: IPayment) {
    if (!payment || !payment.payment_id) return false;
    return this.application.payments.find(x => x.storno?.payment_id === payment.payment_id);
  }
  
  doStorno(payment: IPayment) {
    if (this.hasStorno(payment) || !payment.payment_id) return;
    this.application.payments.push({
      amount: -1 * payment.amount,
      date: DateTime.now().toJSDate(),
      storno: payment,
    });
  }
  
  confirmDelete(event: Event, file: IFile) {
    this.confirmation.confirm({
      target: event.target ?? undefined,
      message: 'Möchten Sie die Datei endgültig löschen?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteFile(file);
      },
    });
  }
  
  deleteFile(file: IFile) {
    if (!this.application.person?.person_id) return;
    this.appService.deleteFile(this.application.person.person_id, file).subscribe((x) => {
      const index = this.files.indexOf(file);
      if (index < 0) return;
      this.files.splice(index, 1);
      this.files = [...this.files];
      this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Delete was successful' });
    });
  }
  
  downloadFile(file: IFile) {
    if (!this.application.application_id) return;
    this.appService.downloadFile(this.application.application_id, file);
  }
  
  upload(event: { files: File[] }, type: FileType = FileType.Undefined) {
    if (!this.application.person?.person_id || event.files.length === 0) return;
    this.appService.uploadFile(this.application.application_id as number, event.files[0], type).subscribe((x) => {
      this.files = [...this.files, x];
      this.messageService.add({ severity: 'success', summary: 'Hochladen erfolgreich' });
      this.fileUpload?.clear();
    });
  }
  
  hasPerm(condition: (perm: Permissions) => boolean) {
    return this.perms.hasPerm(condition);
  }
  
  get sum() {
    let total = 0;
    this.application.payments.map((x) => {
      total += x.amount;
    });
    return total;
  }
  
  public getFileTag(file: IFile) {
    if (!file?.fileType) return file.fileEnding.toUpperCase();
    switch (file.fileType) {
      case FileType.HealthInsurance:
      return 'Krankenkasse';
      case FileType.Personal_ID:
      return 'Ausweis';
      default:
      return file.fileEnding.toUpperCase();
    }
  }
  
  setOwner(person: IPerson) {
    const family = person.family as IFamily;
    family.members.push(family.owner);
    family.owner = person;
  }
  public createFamily(person: IPerson) {
    person.family = new Family(person);
  }
  public loadForm(form: IForm) {
    this.http.get(ConfigService.settings.app.url + '/'+ form.filePath, { responseType: 'blob' }).pipe(
      switchMap(blob => this.convertBlobToBase64(blob))
    ).subscribe((base64ImageUrl: string) => {
      this.document = base64ImageUrl;
    });
  }
  convertBlobToBase64(blob: Blob) {
    return new Observable((observer: { next: (arg0: any) => void; complete: () => void; }) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };
      
      reader.onerror = (event: any) => {
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }
  public documentLoaded(event: LoadEventArgs) {
    const form = this.forms[0];
    if(this.pdfEditor === undefined) return;
    this.canPrint = false;
    const entries = Object.entries(form.bindings);
    form.fields.forEach(x => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.pdfEditor!.formDesignerModule.addFormField(x.type, <any>{ 
        bounds: {
          X: x.bounds.x ?? 0,
          Y: x.bounds.y ?? 0,
          Width: x.bounds.width ?? 0,
          Height: x.bounds.height ?? 0
        }, 
        name: x.name,
        fontFamily: 'Courier',
        fontSize: 10,
        fontStyle: 'None',
        color: 'black',
        borderColor: 'black',
        thickness: 0,
        backgroundColor: 'white',
        alignment: 'Left',
      });
    });
    
    if(form?.bindings){
      Object.entries(form.bindings).forEach(([key, value]) => {
        const field = this.fields.find(f => f.label === key);
        if(!field)
          return;
        field.formField = form.fields.find(f => f.name === value)?.name ?? null;
      });
    }
    
    this.fields.forEach(field => {
      if(!field.formField) return;
      const formFields = this.pdfEditor.retrieveFormFields();
      const formField = formFields.find(f => f.name == field.formField);
      if(!formField) return;
      formField.value = field.path(this.application);
      console.log(field.label, formField.value);
      this.pdfEditor.updateFormFieldsValue(formField);
    });
    this.canPrint = true;
  }
  date2string(date?: Date) {
    return date ? DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT, { locale: 'de' }) : '';
  }
  
  public fields: Field[] = [
    {
      label: 'Versicherungsstatus',
      path: x => this.getEnumName(this.insurantType, x.insurance?.type ),
      formField: null
    },
    {
      label: 'Wunschbeginn',
      path: x => this.date2string(x.start_date),
      formField: null
    },
    {
      label: 'Anlass des Krankenkassenwechsels',
      path: x => x.reason ? this.reasons[x.reason]: '',
      formField: null
    },
    {
      label: 'Krankenkasse',
      path: x => this.healthInsurances.find(h => h.id === x.insurance?.healthInsurance)?.name,
      formField: null
    },
    {
      label: 'Tippgeber',
      path: x => x.referer ? `${x.referer.firstName} ${x.referer.name}` : '',
      formField: null
    },
    //2nd field
    {
      label: 'Vorname',
      path: x => x.person?.firstName,
      formField: null
    },
    {
      label: 'Nachname',
      path: x => x.person?.name,
      formField: null
    },
    {
      label: 'Geschlecht',
      path: x => this.getEnumName(this.genders, x.person?.gender),
      formField: null
    },
    {
      label: 'Familienstand',
      path: x => this.getEnumName(this.familyStates, x.person?.familyStatus),
      formField: null
    },
    {
      label: 'Geburtsdatum',
      path: x => this.date2string(x.person?.birthday),
      formField: null
    },
    {
      label: 'Telefonnummer',
      path: x => x.person?.phone,
      formField: null
    },
    {
      label: 'E-Mail',
      path: x => x.person?.email,
      formField: null
    },
    {
      label: 'Straße',
      path: x => x.person?.street,
      formField: null
    },
    {
      label: 'Hausnummer',
      path: x => x.person?.streetNumber,
      formField: null,
    },
    {
      label: 'Stadt',
      path: x => x.person?.city,
      formField: null
    },
    {
      label: 'PLZ',
      path: x => x.person?.postcode,
      formField: null
    },
    //3rd field
    {
      label: 'Arbeitgebername',
      path: x => x.employment?.employer?.name,
      formField: null
    },
    //Bisherige Krankenversicherung 4th field
    {
      label: 'Krankenkasse',
      path: x => this.healthInsurances.find(h => h.id === x.previousInsurance?.healthInsurance)?.name,
      formField: null
    },
    {
      label: 'Versicherungsnummer',
      path: x => x.insurance?.insurance_number,
      formField: null
    },
    {
      label: 'Von',
      path: x => this.date2string(x.insurance?.start_date),
      formField: null
    },
    {
      label: 'Bis',
      path: x => this.date2string(x.insurance?.end_date),
      formField: null
    },
    {
      label: 'Ich war zuletzt',
      path: x => x.insurance?.pastInsuranceRelation ? this.pastInsuranceRelations[x.insurance.pastInsuranceRelation] : '',
      formField: null
    },
    {
      label: 'Seit',
      path: x => this.date2string(x.insurance?.data?.notInsuredSince),
      formField: null
    },
    {
      label: 'Grund',
      path: x => x.insurance?.data?.notInsuredReason,
      formField: null
    },
    {
      label: 'familienversichert über',
      path: x => x.insurance?.data?.familyInsuredName,
      formField: null
    },
    {
      label: 'Versicherungsnummer des Familienversicherers',
      path: x => x.insurance?.data?.familyInsuredNumber,
      formField: null
    },
    //Bankverbindung 5th field
    {
      label: 'IBAN',
      path: x => x.person?.iban,
      formField: null
    },
    {
      label: 'BIC',
      path: x => x.person?.bic,
      formField: null
    },
  ];
  getEnumName(map: EnumMap, value?: number){
    if(!value)
      return '';
    return map[value];
  }


  genders: EnumMap = EnumToRecord(Gender);
  familyStates: EnumMap = EnumToRecord(FamilyStatus);
  insurantType: EnumMap = EnumToRecord(InsurantType);
  reasons: EnumMap = EnumToRecord(ChangeReason);
  healthInsurances: HealthInsurance[] = healthInsurances;
  pastInsuranceRelations: EnumMap = EnumToRecord(PastInsuranceRelation);
}

class Payment implements IPayment {
  payment_id?: number;
  state!: PaymentState;
  date!: Date;
  amount!: number;
  
  constructor(amount = 0, state: PaymentState = PaymentState.PaidIn) {
    this.amount = amount;
    this.state = state;
    this.date = new Date();
  }
}